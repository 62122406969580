import React from "react";
import { connect } from "react-redux";
import Link from "gatsby-link";
import LanguageSelector from "../languageSelector";
import "./style.scss";


const MobileNavigation = ({ currentPath, language }) => {
  
  return (
    <nav className="mobileNav">
      {language === 'fi'?
      <ul>
        <li>      
          <Link className={currentPath === "/" ? 'current' : null} to="/"><span>Kartta</span></Link>
        </li>
        <li>
         
          <Link className={currentPath === "/tietoa-sivustosta" ? 'current' : null} to="/tietoa-sivustosta"> <span>Info</span></Link>
        </li>
        <li>
          
          <Link className={currentPath === "/palaute" ? 'current' : null} to="/palaute"><span>Palaute</span></Link>
        </li>

      </ul>: null}

      {language === 'sv'?
      <ul>
        <li>      
          <Link className={currentPath === "/" ? 'current' : null} to="/"><span>Karta</span></Link>
        </li>
        <li>
         
          <Link className={currentPath === "/tietoa-sivustosta" ? 'current' : null} to="/tietoa-sivustosta"> <span>Info</span></Link>
        </li>
        <li>
          
          <Link className={currentPath === "/palaute" ? 'current' : null} to="/palaute"><span>Respons</span></Link>
        </li>

      </ul>: null}

      {language === 'en'?
      <ul>
        <li>      
          <Link className={currentPath === "/" ? 'current' : null} to="/"><span>Map</span></Link>
        </li>
        <li>
         
          <Link className={currentPath === "/tietoa-sivustosta" ? 'current' : null} to="/tietoa-sivustosta"> <span>Info</span></Link>
        </li>
        <li>
          
          <Link className={currentPath === "/palaute" ? 'current' : null} to="/palaute"><span>Feedback</span></Link>
        </li>

      </ul>: null}

      <LanguageSelector />
    </nav>
  )
}



export default connect(state => ({
  language: state.language.language
 }), null)(MobileNavigation);