import { Link } from "gatsby"
import React, { Component } from "react"
import { connect } from "react-redux"

import Navigation from "../navigation"
import MobileNavigation from "../mobileNavigation"
import LanguageSelector from "../languageSelector"
import LogoSet from "../../images/logoset.svg"
import Logo from "../../images/logo.svg"
import { FaBars, FaTimes } from "react-icons/fa"
import EmbedButton from "../EmbedButton"

import "./style.scss"

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileNavOpen: false,
    }
    this.toggleMobileNav = this.toggleMobileNav.bind(this)
  }

  toggleMobileNav() {
    this.setState({ mobileNavOpen: !this.state.mobileNavOpen })
  }

  componentDidUpdate(prevProps) {
    if (this.props.language !== prevProps.language) {
      this.setState({ mobileNavOpen: false })
    }
  }

  render() {
    return (
      <div className="headerWrap no-print">
        {this.props.vw > 1024 ? (
          <div className="desktopHeader">
            <Navigation
              currentPath={this.props.currentPath}
              language={this.props.language}
            />

            <div className="logoContainer">
              <Link to="/">
                <LogoSet />
              </Link>
            </div>
            <LanguageSelector />
            <EmbedButton />
          </div>
        ) : null}
        {this.props.vw <= 1024 && this.props.vw !== 0 ? (
          <div className="mobileHeader grid-x">
            <div className="cell small-2 mobileMenuToggleContainer">
              <div className="openNavBtn">
                {!this.state.mobileNavOpen ? (
                  <FaBars onClick={this.toggleMobileNav} />
                ) : (
                  <FaTimes onClick={this.toggleMobileNav} />
                )}
              </div>
            </div>
            <div className="cell small-8 logoContainer">
              <Link to="/">
                <Logo />
              </Link>
            </div>
            <div className="cell small-2 filterToggleContainer"></div>
          </div>
        ) : null}
        {this.props.vw <= 1024 &&
        this.props.vw !== 0 &&
        this.state.mobileNavOpen ? (
          <MobileNavigation
            currentPath={this.props.currentPath}
            language={this.props.language}
          />
        ) : null}
      </div>
    )
  }
}

export default connect(
  state => ({
    language: state.language.language,
  }),
  null
)(Header)
