import React, {Component} from "react";
import { connect } from "react-redux";
import {changeLanguage} from "../../state/language";
import "./style.scss";

class LanguageSelector extends Component {

  componentDidMount() {
    // initialize here
  }

  render() {
    return (
    <div className="languageSelector">
      <ul>
        <li>
        <a 
        href="#"
        className={this.props.language === 'fi' ? 'currentLang' : null} 
        onClick={ () => this.props.changeLanguage('fi')}
        >
          FI
          </a>
        </li>
        <li>
        <a 
        href="#"
        className={this.props.language === 'sv' ? 'currentLang' : null}
        onClick={ () => this.props.changeLanguage('sv')}
        >
          SV
          </a>
        </li>
        <li>
        <a 
        href="#"
        className={this.props.language === 'en' ? 'currentLang' : null}
        onClick={ () => this.props.changeLanguage('en')}
        >
          EN
          </a>
        </li>
      </ul>
    </div>
  )
  }
  
}
const mapDispatchToProps = dispatch => ({
  changeLanguage: lang => dispatch(changeLanguage(lang)),
});

export default connect(state => ({
   language: state.language.language,
 }), mapDispatchToProps)(LanguageSelector);