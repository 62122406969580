import React from "react";
import { connect } from 'react-redux';
import "./layout.scss";
import Header from "./header";
import { StaticQuery } from 'gatsby'
import SEO from "./seo";
import { initMaterials } from '../state/app';

import "../assets/css/animate.css";



const Layout = ({ children, currentPath, vw, title, dispatch, description, image }) => (
  <StaticQuery
  query={graphql`
    query layoutQuery {
      materials: allContentfulMaterial {
        edges {
          node {
            name
            code
            language
            slug
          }
        }
      }
    }
  `}
  render={data => {
    dispatch(initMaterials(data.materials.edges));

    return (
      <div className="layout">
        <SEO description="placeholder desc" lang="fi" title={title} description={description} image={image}/>
        <Header
          vw={vw}
          currentPath={currentPath}
        />
   
      
            {children}
        
    
       
      
      </div>
    )} }/>
);


export default connect(null, null)(Layout);
