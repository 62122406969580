import React from "react";
import Link from "gatsby-link";
import RecyclingIcon from '../../images/icons/icon-recycling.svg';
import ParnersIcon from '../../images/icons/icon-partners.svg';
import FeedbackIcon from '../../images/icons/icon-feedback.svg';
import InfoIcon from '../../images/icons/icon-info.svg';
import "./style.scss";


const Navigation = ({ currentPath, language }) => {
 
  return (
    <nav className="mainNav">
      {language === 'fi' ?
        <ul>
          <li>      
            <Link className={currentPath === "/" ? 'current' : null} to="/"><RecyclingIcon /><span>Kartta</span></Link>
          </li>
          <li>
          
            <Link className={currentPath === "/tietoa-sivustosta" ? 'current' : null} to="/tietoa-sivustosta"> <InfoIcon /><span>Info</span></Link>
          </li>
          <li>
            
            <Link className={currentPath === "/palaute" ? 'current' : null} to="/palaute"><FeedbackIcon /><span>Palaute</span></Link>
          </li>
        </ul>
      :null}

      {language === 'sv' ?
        <ul>
          <li>      
            <Link className={currentPath === "/" ? 'current' : null} to="/"><RecyclingIcon /><span>Karta</span></Link>
          </li>
          <li>
            
            <Link className={currentPath === "/tietoa-sivustosta" ? 'current' : null} to="/tietoa-sivustosta"><InfoIcon /><span>Info</span></Link>
          </li>
          <li>
            
            <Link className={currentPath === "/palaute" ? 'current' : null} to="/palaute"><FeedbackIcon /><span>Respons</span></Link>
          </li>
        </ul>
      :null}

      {language === 'en' ?
        <ul>
          <li>      
            <Link className={currentPath === "/" ? 'current' : null} to="/"><RecyclingIcon /><span>Map</span></Link>
          </li>
          <li>
            
            <Link className={currentPath === "/tietoa-sivustosta" ? 'current' : null} to="/tietoa-sivustosta"><InfoIcon /><span>Info</span></Link>
          </li>
          <li>
            
            <Link className={currentPath === "/palaute" ? 'current' : null} to="/palaute"><FeedbackIcon /><span>Feedback</span></Link>
          </li>
        </ul>
      :null}  

    </nav>

)
}



export default Navigation;
