import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { FaShare, FaTimes } from "react-icons/fa"

import "./style.scss"

const EmbedButton = ({ center, zoom, filters }) => {
  const [show, setShow] = useState(false)
  const [currentFilters, setCurrentFilters] = useState("")
  const [domain, setDomain] = useState("https://kierratys.info")

  useEffect(() => {
    if (filters.includes("Kaikki")) {
      setCurrentFilters("")
    } else {
      const commaSeparated = filters.join()
      setCurrentFilters(commaSeparated)
    }
    setDomain(window.location.origin)
  }, [filters])

  return (
    <>
      <div className="headerEmbedBtnContainer">
        <FaShare
          onClick={() => setShow(!show)}
          style={{ color: "white", fontSize: "1em" }}
        />
      </div>
      {show && (
        <div className="embedModal">
          <h3>Upota karttanäkymä</h3>
          <div>
            <textarea
              value={`<iframe src="${domain}/embed?lat=${center[0]}&lng=${center[1]}&zoom=${zoom}&filters=${currentFilters}" style="border:none;" height="600" width="600" title="kierratys.info"></iframe>`}
            />
          </div>
          <h3>Jaa linkki näkymään</h3>
          <div>
            <textarea
              value={`${domain}?lat=${center[0]}&lng=${center[1]}&zoom=${zoom}&filters=${currentFilters}`}
            />
          </div>
          <FaTimes
            onClick={() => setShow(false)}
            style={{ color: "black", fontSize: "1em" }}
          />
        </div>
      )}
    </>
  )
}

export default connect(
  state => ({
    center: state.app.currentCenter,
    zoom: state.app.currentZoom,
    filters: state.app.activeFilter,
  }),
  null
)(EmbedButton)
